import React from "react";
import IndexHero from "./index-hero";
import IndexFeatures from "./index-features";

export default class Index extends React.Component {
    render() {
        return (
            <div className={'leading-normal text-lg'}>
                <IndexHero/>
                <IndexFeatures/>

                {/*<IndexScreenshot/>
                <IndexBigTextBlock text={'index.big-text.block1'}/>
                */}
            </div>
        )
    }
}

export {Index};
