import React from "react";
import {Link} from "gatsby";
import PropTypes from "prop-types";
import classNames from "classnames";

export default class SidebarNavItem extends React.Component {

    static propTypes = {
        onClick: PropTypes.func.isRequired
    };

    static getClassNames({isPartiallyCurrent, isCurrent, href}) {
        const isRootPath = href.endsWith("/") || href.endsWith("/de");
        return {
            className: classNames(
                'block py-1 mx-2 my-1 px-2 text-sm font-medium border-l-4 ',
                (isCurrent || (isPartiallyCurrent && !isRootPath)) ? "border-primary-500 text-primary-900 bg-primary-100"
                    : "border-transparent text-gray-900"
            )
        };
    };

    render() {
        return (
            <Link to={this.props.link}
                  getProps={SidebarNavItem.getClassNames}
                  onClick={this.props.onClick}
            >
                {this.props.children}
            </Link>
        )
    };
}
