import React from 'react';
import DefaultIcon from '../svg/font-awesome/light/acorn.svg';
import HtmlMessage from "./html-message";

export default class Feature extends React.Component {

    render() {
        return <div>
            <div className="w-16 mx-auto">
                {this.props.icon}
            </div>
            <h4 className={'text-xl mt-4 mb-2 font-bold'}>
                <HtmlMessage
                    id={this.props.title}
                    values={this.props.values}
                /></h4>

            <HtmlMessage
                id={this.props.text}
                values={this.props.values}
            />

            {this.props.children}
        </div>
    }
};

Feature.defaultProps = {
    title: 'TITLE',
    icon: <DefaultIcon/>
};

