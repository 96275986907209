import React from 'react';
import PropTypes from 'prop-types';
import Footer from "./footer";
import NavbarHeader from "./navbar/navbar-header";

import mainNavItems from "../../config/main-nav";
import sideNav from "../../config/side-nav";

import '../../css/style.css'

import {IntlProvider} from "react-intl";

import messages_de from "../../translations/de.json";
import messages_en from "../../translations/en.json";
import mdxComponents from "../mdx";
import {MDXProvider} from "@mdx-js/react";
import Sidebar from "./sidebar/sidebar";
import SEO from "../seo";
import Helmet from "react-helmet";

require('@formatjs/intl-pluralrules/polyfill');
require('@formatjs/intl-pluralrules/dist/locale-data/de'); // Add locale data for de
require('@formatjs/intl-pluralrules/dist/locale-data/en'); // Add locale data for en

const messages = {
    'de': messages_de,
    'en': messages_en
};


export default class PageLayout extends React.Component {

    static propTypes = {
        children: PropTypes.node.isRequired,
        sidebarContents: PropTypes.array,
        location: PropTypes.object.isRequired
    };

    state = {showMenu: false};

    constructor(props) {
        super(props);
        this.toggleMenu = this.toggleMenu.bind(this);
    }

    toggleMenu() {
        this.setState({
            showMenu: !this.state.showMenu
        })
    }

    sidebar() {
        return this.props.data?.mdx?.frontmatter?.sidebar;
    }

    sidebarContent() {
        return sideNav[this.props.data.mdx.frontmatter.sidebar ?? 'none'];
    }

    hideSideBar() {
        this.setState({
            showMenu: false
        })
    }

    render() {
        const lang = this.props.pageContext.lang;
        const translatedUrls = this.props.pageContext.translatedPageUrls;

        return (
            <IntlProvider locale={lang} messages={messages[lang]}>

                <SEO lang={lang} frontmatter={this.props.data.mdx.frontmatter}/>

                <Helmet>
                    {Object.keys(translatedUrls).map((key) =>
                        <link rel="alternate" key={key} hrefLang={key} href={translatedUrls[key]}/>
                    )}
                </Helmet>

                <MDXProvider components={mdxComponents}>
                    <div id="app" className="h-screen flex flex-col">
                        <NavbarHeader navItems={mainNavItems[lang]}
                                      translatedPageUrls={translatedUrls}
                                      lang={lang}
                                      onToggleMenu={this.toggleMenu}
                        />
                        <div className={"flex-1 flex mt-10 " + (this.sidebar() ? 'sm:ml-64' : '')}>
                            <Sidebar hideSideBar={this.hideSideBar}
                                     sidebarOpen={this.state.showMenu}
                                     navItems={mainNavItems[lang]}
                                     location={this.props.location}
                                     sidebarContents={this.sidebarContent()}
                            />
                            <div className={'w-full flex flex-col justify-between'}>
                                {this.props.children}
                                <Footer lang={lang}/>
                            </div>
                        </div>
                    </div>
                </MDXProvider>
            </IntlProvider>
        );
    }
}
