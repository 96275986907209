import PlanSelector from "./plan-selector";
import PricingPlanSummary from "./pricing-plan-summary";
import FAQ from "../faq/f-a-q";
import PricingPlanFeatures from "./pricing-plan-features";
import React from "react";

const pricingFAQ = [
    [
        'pricing.faq.what-is-an-action',
        'pricing.faq.what-is-a-project'
    ],
    [
        'pricing.faq.track-more',
        'pricing.faq.try',
    ],
    [
        'pricing.faq.retention',
        'pricing.faq.action-count',
    ],
    [
        'pricing.faq.exceed-quota',
        null
    ]
];

const Pricing = () => (
    <div className="container mx-auto px-4 py-24">
        <PlanSelector/>
        <PricingPlanSummary/>
        <FAQ title={'pricing.faq.title'} items={pricingFAQ}/>

        <h2 className={'mt-8 text-2xl font-semibold'}>MarvelMetrix features</h2>
        <p className={'mt-4'}>
            The following tables give you a brief overview over the available features in MarvelMetrix.
        </p>
        <PricingPlanFeatures/>
    </div>
);

export {Pricing};

