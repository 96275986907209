import React from "react";
import {Link} from "gatsby";
import {ReactComponent as MmLogo} from "../../../img/marvelmetrix-logo.svg";
import {ReactComponent as LanguageIcon} from "../../../svg/font-awesome/light/globe.svg";
import PropTypes from "prop-types";
import NavbarToggle from "./navbar-toggle";
import NavbarLinks from "./navbar-links";
import {FormattedMessage, injectIntl} from "react-intl";

class OtherLanguageButton extends React.Component {

    static propTypes = {
        translatedPageUrls: PropTypes.object.isRequired,
        lang: PropTypes.string.isRequired
    };

    getOtherLanguageUrl() {
        const lang = this.props.lang;
        const otherLang = lang === "en" ? "de" : "en";
        return this.props.translatedPageUrls[otherLang] ?? (otherLang === "en" ? "/" : "/" + otherLang);
    }

    render() {
        return (
            <Link
                to={this.getOtherLanguageUrl()}
                className="block sm:ml-4 text-gray-700 hover:text-gray-900 whitespace-no-wrap">
                <LanguageIcon className="svg-inline--fa h-5 w-5 fill-current mr-1"/>
                <FormattedMessage id={'common.other-language'}/>
            </Link>
        );
    }
}


class NavbarHeader extends React.Component {

    static propTypes = {
        onToggleMenu: PropTypes.func.isRequired,
        navItems: PropTypes.array.isRequired,
        translatedPageUrls: PropTypes.object.isRequired,
        lang: PropTypes.string.isRequired
    };

    render() {
        const rootPath = this.props.lang === "en" ? "/" : "/"+this.props.lang;
        return (
            <header
                className="fixed bg-white w-full z-30 top-0 border-b border-t-2 border-primary-700 shadow-md">
                <div className={'container mx-auto flex flex-shrink-0 bg-white items-center'}>
                    <div
                        className="flex-grow sm:flex-grow-0 sm:flex-shrink-0 px-4 md:px-8 py-2 lg:w-64 flex"
                    >
                        <NavbarToggle onToggleMenu={this.props.onToggleMenu}/>
                        <Link to={rootPath} className="mx-auto sm:ml-0" aria-label="MarvelMetrix Logo">
                            <MmLogo className={'block h-6'}/>
                        </Link>
                    </div>
                    <div className="flex-grow-0 flex sm:flex-grow items-center justify-between px-4">
                        <NavbarLinks navItems={this.props.navItems}/>
                        <OtherLanguageButton lang={this.props.lang} translatedPageUrls={this.props.translatedPageUrls}/>
                    </div>
                </div>
            </header>
        )
    }
}

export default injectIntl(NavbarHeader);
