import {ReactComponent as FaMousePointerBullsEye} from "../../svg/font-awesome/light/bullseye-pointer.svg";
import {ReactComponent as FaFilter} from "../../svg/font-awesome/light/filter.svg";
import {ReactComponent as FaPrivacy} from "../../svg/font-awesome/light/user-secret.svg";
import {ReactComponent as FaAnalytics} from "../../svg/font-awesome/light/analytics.svg";
import {ReactComponent as FaSearch} from "../../svg/font-awesome/light/search.svg";
import {ReactComponent as FaTransfer} from "../../svg/font-awesome/light/person-carry.svg";
import Feature from "../../components/feature";
import React from "react";
import Link from "gatsby-link";
import {tailwind} from "../../components/caw-core/style";

const Row = tailwind.div(`flex flex-wrap -mx-4`);
const Col = tailwind.div(`w-full md:w-1/2 lg:w-1/3 px-8`);

const iconClass='svg-inline--fa fa-2x fill-current text-primary-100';

export default class IndexFeatures extends React.Component {
    render() {
        return (
            <div className="bg-primary-700 text-primary-100 pb-12">
                <div className={"container mx-auto text-center"}>
                    <Row>
                        <Col className="mt-12">
                            <Feature title={'index.features.tracking.title'}
                                     icon={
                                         <FaMousePointerBullsEye className={iconClass}/>
                                     }
                                     text={'index.features.tracking.text'}
                            />
                        </Col>

                        <Col className="mt-12">
                            <Feature title={'index.features.custom-reports.title'}
                                     icon={
                                         <FaFilter className={iconClass}/>
                                     }
                                     text={'index.features.custom-reports.text'}
                            />
                        </Col>
                        <Col className="mt-12">
                            <Feature title={'index.features.gdpr.title'}
                                     icon={
                                         <FaPrivacy className={iconClass}/>
                                     }
                                     text={'index.features.gdpr.text'}
                                     values={{
                                         link_en: (...chunks) => <Link className={'underline'}
                                                                       to={'/gdpr'}>{chunks}</Link>,
                                         link_de: (...chunks) => <Link className={'underline'}
                                                                       to={'/de/dsgvo'}>{chunks}</Link>,
                                     }}
                            />
                        </Col>

                        <Col className="mt-12">
                            <Feature title={'index.features.funnels.title'}
                                     icon={
                                         <FaAnalytics className={iconClass}/>
                                     }
                                     text={'index.features.funnels.text'}
                            />
                        </Col>

                        <Col className="mt-12">
                            <Feature title={'index.features.analytics.title'}
                                     icon={
                                         <FaSearch className={iconClass}/>
                                     }
                                     text={'index.features.analytics.text'}
                            />
                        </Col>

                        <Col className="mt-12">
                            <Feature title={'index.features.your-data.title'}
                                     icon={
                                         <FaTransfer className={iconClass}/>
                                     }
                                     text={'index.features.your-data.text'}
                            />
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}
