import React from "react";
import NavbarItem from "./navbar-item";
import PropTypes from "prop-types";

export default class NavbarLinks extends React.Component {

    static propTypes = {
        navItems: PropTypes.array.isRequired
    };


    render() {
        return (
            <nav className="hidden sm:flex">
                {this.props.navItems.map((item) => <NavbarItem key={"topnav-"+item.link} link={item.link}>{item.title}</NavbarItem>)}
            </nav>
        )
    }
}
