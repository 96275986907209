import React from 'react';
import {FormattedMessage} from "react-intl";

const defaultResolutions = {
    br: <br/>,
    b: (...chunks) => <b>{chunks}</b>,
    p: (...chunks) => <p>{chunks}</p>,
    h2: (...chunks) => <h2>{chunks}</h2>,
    em: (...chunks) => <em>{chunks}</em>
};

export default class HtmlMessage extends React.Component {
    render() {
        return (
            <FormattedMessage
                id={this.props.id}
                values={{...defaultResolutions,...this.props.values}}
            />
        )
    }
};
