const mainNavItems =
    {
        'en': [
            {
                link: "/",
                title: "Product"
            },
            {
                link: "/gdpr",
                title: "GDPR"
            },
            {
                link: "/pricing",
                title: "Pricing"
            },
            {
                link: "/documentation/basics",
                title: "Documentation"
            },
        ],

        'de': [
            {
                link: "/de",
                title: "Produkt"
            },
            {
                link: "/de/dsgvo",
                title: "DSGVO"
            },
            {
                link: "/de/preise",
                title: "Preise"
            },
            {
                link: "/de/dokumentation/grundlagen",
                title: "Dokumentation"
            },
        ]
    }
;

export default mainNavItems;

