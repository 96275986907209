import React from "react";
import HtmlMessage from "../../components/html-message";
import {tailwind} from "../../components/caw-core/style";
import classNames from "classnames";

const Price = tailwind.div(`text-5xl pb-2`);
const PriceDuration = tailwind.div(`text-sm text-gray-600`);
const FeatureList = tailwind.ul(`text-gray-600 leading-loose list-reset mb-6`);
const PlanName = tailwind.h2(`text-lg mb-2 font-bold`);


class Item extends React.Component {
    render() {
        return <li><HtmlMessage id={this.props.id}/></li>
    }
}

class ItemNotAvailable extends React.Component {
    render() {
        return <li>&mdash;</li>
    }
}


const ButtonBase = `text-sm tracking-wide uppercase inline-block border border-red-700 font-bold w-full p-3 rounded no-underline`;
const ButtonWhite = tailwind.span(ButtonBase, `bg-white text-red-700`);
const ButtonRed = tailwind.span(ButtonBase, `bg-red-700 text-white`);

class Plan extends React.Component {
    render() {
        return <div className="mb-4 lg:mb-0 lg:w-1/3 lg:px-2">
            <button
               className={classNames(
                   "block w-full text-center p-10 rounded",
                   "border border-primary-300",
                   "hover:shadow-lg hover:border-primary-400",
                   {"lg:py-16" : this.props.big}
                   )}
            >{this.props.children}</button>
        </div>
    }
}


export default class PlanSelector extends React.Component {
    render() {
        return <div>
            <header className="text-center mb-16">
                <h1 className="text-5xl mb-4"><HtmlMessage id={'pricing.plan-selector.title'}/></h1>
                <p className="leading-normal text-gray-600 md:w-1/2 md:mx-auto">
                    <HtmlMessage id={'pricing.plan-selector.description'}/>
                </p>
            </header>

            <div className="lg:flex lg:items-center lg:-mx-2">
                <Plan>
                    <PlanName>Starter</PlanName>
                    <div className="mb-6">
                        <Price>€14</Price>
                        <PriceDuration><HtmlMessage id={'pricing.plan-selector.monthly'}/></PriceDuration>
                    </div>
                    <FeatureList>
                        <Item id={'pricing.plan-selector.projects.1'}/>
                        <Item id={'pricing.plan-selector.actions.100k'}/>
                        <Item id={'pricing.plan-selector.data-retention.90d'}/>
                        <ItemNotAvailable/>
                    </FeatureList>
                    <ButtonWhite><HtmlMessage id={'pricing.plan-selector.get-started-button'}/></ButtonWhite>
                </Plan>

                <Plan big={'true'}>
                    <PlanName>Growth</PlanName>
                    <div className="mb-6">
                        <Price>€49</Price>
                        <PriceDuration><HtmlMessage id={'pricing.plan-selector.monthly'}/></PriceDuration>
                    </div>
                    <FeatureList>
                        <Item id={'pricing.plan-selector.projects.5'}/>
                        <Item id={'pricing.plan-selector.actions.500k'}/>
                        <Item id={'pricing.plan-selector.data-retention.1y'}/>
                        <Item id={'pricing.plan-selector.advanced-features'}/>
                    </FeatureList>
                    <ButtonRed><HtmlMessage id={'pricing.plan-selector.get-started-button'}/></ButtonRed>
                </Plan>

                <Plan>
                    <PlanName>Pro</PlanName>
                    <div className="mb-6">
                        <Price>€149</Price>
                        <PriceDuration><HtmlMessage id={'pricing.plan-selector.monthly'}/></PriceDuration>
                    </div>
                    <FeatureList>
                        <Item id={'pricing.plan-selector.projects.20'}/>
                        <Item id={'pricing.plan-selector.actions.2M'}/>
                        <Item id={'pricing.plan-selector.data-retention.2y'}/>
                        <Item id={'pricing.plan-selector.advanced-features'}/>
                    </FeatureList>
                    <ButtonWhite><HtmlMessage id={'pricing.plan-selector.get-started-button'}/></ButtonWhite>
                </Plan>
            </div>

            <p className="text-center mt-16">
                <HtmlMessage id={'pricing.plan-selector.need-more-text'}/>
            </p>
        </div>
    }
};
