import React from "react";
import PropTypes from "prop-types";
import SidebarNavLinks from "./sidebar-navlinks";
import classNames from 'classnames';
import SidebarCategoryList from "./sidebar-category-list";

/*
const CloseIcon = () => (
    <svg className="h-6 w-6" viewBox="0 0 24 24" fill="currentColor">
        <path
            d="M17.293 18.707a1 1 0 001.414-1.414L13.414 12l5.293-5.293a1 1 0 00-1.414-1.414L12 10.586 6.707 5.293a1 1 0 00-1.414 1.414L10.586 12l-5.293 5.293a1 1 0 101.414 1.414L12 13.414l5.293 5.293z"
        />
    </svg>
);
 */

export default class Sidebar extends React.Component {

    static propTypes = {
        sidebarOpen: PropTypes.bool,
        navItems: PropTypes.array,
        sidebarContents: PropTypes.array,
        hideSideBar: PropTypes.func.isRequired,
        location: PropTypes.object.isRequired
    };

    constructor(props) {
        super(props);
        this.linkClick = this.linkClick.bind(this);
    }


    linkClick() {
        this.props.hideSideBar();
    }


    render() {
        return (
            <div
                className={
                    classNames(
                        "mt-10 z-10 fixed inset-y-0 left-0 w-64 bg-gray-100 border-r overflow-y-auto bg-white",
                        this.props.sidebarOpen ? 'translate-x-0 ease-out transition-slow' : '-translate-x-full ease-in transition-medium',
                        {
                            'sm:translate-x-0 sm:transition-none': this.props.sidebarContents
                        }
                    )
                }
            >
                {/*
                <div className="absolute top-0 left-0 pl-4 pt-3 sm:hidden">
                    <button onClick={this.linkClick} className="block text-gray-600 hover:text-gray-800">
                        <CloseIcon/>
                    </button>
                </div>
                */}

                <nav className="mt-4 sm:mt-0">
                    <SidebarNavLinks
                        navItems={this.props.navItems}
                        onClick={this.linkClick}
                    />

                    {this.props.sidebarContents && (
                        <SidebarCategoryList
                            categories={this.props.sidebarContents}
                            onClick={this.linkClick}
                        />
                    )}
                </nav>
            </div>
        );
    }
}
