import React from 'react';
import HtmlMessage from "../html-message";
import classNames from "classnames";

const removeClassName = ({className, ...rest}) => {
    return rest;
};

const createElement = (type, cssClasses) => {
    return (props) => React.createElement(type, {
        className: classNames(...cssClasses, props.className),
        ...removeClassName(props)
    })
};

const tailwind = {
    div: (...cssClasses) => createElement('div', cssClasses),
    span: (...cssClasses) => createElement('span', cssClasses),
    ul: (...cssClasses) => createElement('ul', cssClasses),
    li: (...cssClasses) => createElement('li', cssClasses),
    h1: (...cssClasses) => createElement('h1', cssClasses),
    h2: (...cssClasses) => createElement('h2', cssClasses),
    h3: (...cssClasses) => createElement('h3', cssClasses),
    a: (...cssClasses) => createElement('a', cssClasses),
    button: (...cssClasses) => createElement('button', cssClasses),
    img: (...cssClasses) => createElement('img', cssClasses),
};



const baseAlert=`font-bold border-l-4 shadow-md px-4 py-2 mb-4`;

const AlertDanger = tailwind.div('bg-red-100 text-red-500 border-red-500', baseAlert);
const AlertWarning = tailwind.div('bg-orange-100 text-orange-500 border-orange-500', baseAlert);
const AlertInfo = tailwind.div('bg-indigo-100 text-indigo-500 border-indigo-500', baseAlert);

const Label = tailwind.div('whitespace-no-wrap rounded-full inline bg-primary-100 text-primary-800 px-2 text-xs font-semibold shadow tracking-wide uppercase');

const CSLabel = tailwind.div('rounded-full inline bg-primary-100 text-primary-500 px-2 text-xs tracking-wide uppercase whitespace-no-wrap');

class ComingSoon extends React.Component {
    render() {
        return <CSLabel><HtmlMessage id={'common.coming-soon-label'}/></CSLabel>;
    };
};

class BilledSeparately extends React.Component {
    render() {
        return <CSLabel><HtmlMessage id={'common.billed-separately-label'}/></CSLabel>;
    };
};

const Row = tailwind.div(`flex flex-wrap -mx-2`);
const Col6 = tailwind.div(`w-full lg:w-1/2 px-2`);
const Col4 = tailwind.div(`w-full lg:w-1/3 px-2`);

const Tag = tailwind.span(`inline-block bg-gray-100 rounded-full px-3 py-1 text-sm font-semibold text-gray-800 mr-2`);


const Card = tailwind.div(`rounded overflow-hidden shadow-lg`);
const CardImage = tailwind.img(`w-full`);
const CardBody = tailwind.div(`px-6 py-4`);
const CardTitle = tailwind.div(`font-bold text-xl mb-2`);
const CardText = tailwind.div(`text-gray-800 text-base`);


export {
    tailwind,

    AlertDanger,
    AlertWarning,
    AlertInfo,
    Label,
    Tag,

    Row,
    Col6,
    Col4,

    Card, CardImage, CardBody, CardTitle, CardText,

    ComingSoon,
    BilledSeparately
}
