import React from "react";
import {Col6, Row} from "../../components/caw-core/style";
import HtmlMessage from "../../components/html-message";
import PropTypes from "prop-types";

const FAQItem = (props) => (
    <Col6 className={"mb-8"}>
        <h3 className={"text-1xl font-bold mb-4"}>
            <HtmlMessage id={`${props.item}.title`}/>
        </h3>
        <p>
            <HtmlMessage id={`${props.item}.text`}/>
        </p>
    </Col6>
);

const FAQRow = (props) => (
    <Row>
        {props.row.map((item) => (
            item ? (
                <FAQItem key={'faq-item-'+item} item={item}/>
            ) : null
        ))}
    </Row>
);

class FAQ extends React.Component {
    render() {
        const elements = this.props.items.map((row, index) => (
            <FAQRow key={'faq-'+this.props.title+index} row={row}/>
        ));
        return (
            <div className={'my-16'}>
                <h2 className={"text-2xl font-bold mb-4 px-1"}>
                    <HtmlMessage id={this.props.title}/>
                </h2>
                {elements}
            </div>
        )
    }
}

FAQ.propTypes = {
    title: PropTypes.string,
    items: PropTypes.arrayOf(
        PropTypes.arrayOf(
            PropTypes.string
        )
    )
};

export default FAQ;
