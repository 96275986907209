const footerNavLinks = {
    'en': [
        {
            link: "/about",
            title: "Imprint"
        },
        {
            link: "/privacy",
            title: "Privacy Policy"
        },
    ],
    'de': [
        {
            link: "/de/impressum",
            title: "Impressum"
        },
        {
            link: "/de/datenschutz",
            title: "Datenschutz"
        },
    ]
};

export default footerNavLinks;
