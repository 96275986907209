import React from "react";
import Title from "../../components/feature-table/title";
import Summary from "../../components/feature-table/summary";


export default class PricingPlanSummary extends React.Component {
    render()
    {
        return <div className="bg-white shadow-md rounded my-16">
            <table
                className="text-left w-full border-collapse">
                <thead>
                <Title description={'pricing.pricing-plan.plan-summary.title'}/>
                </thead>
                <tbody>
                <Summary description={"pricing.pricing-plan.plan-summary.number-of-projects"} values={[1, 5, 20]}/>
                <Summary description={"pricing.pricing-plan.plan-summary.number-of-events"}
                         values={['100.000', '500.000', '2.000.000']}/>
                <Summary description={"pricing.pricing-plan.plan-summary.data-retention"}
                         values={['90 days', '1 year', '2 years']}/>
                <Summary description={"pricing.pricing-plan.plan-summary.support"} values={['email', 'email', 'email']}/>
                </tbody>
            </table>
        </div>
    };
};

