import React from "react";
import HtmlMessage from "../html-message";
import PropTypes from "prop-types";

export default class Title extends React.Component {

    static propTypes = {
        description: PropTypes.string.isRequired
    };

    render() {

        const planCssClass="py-4 px-6 font-bold text-center uppercase text-sm text-primary-800 border-b border-primary-200";

        return (
            <tr className={'bg-primary-100'}>
                <th className="py-4 px-6 font-bold uppercase text-sm text-primary-800 border-b border-primary-200">
                    <HtmlMessage id={this.props.description}/>
                </th>
                <th className={planCssClass}>
                    Starter
                </th>
                <th className={planCssClass}>
                    Growth
                </th>
                <th className={planCssClass}>
                    Pro
                </th>
            </tr>
        )
    }
}
