import * as React from "react";
import HtmlMessage from "../html-message";
import PropTypes from "prop-types";

export default class Summary extends React.Component {

    static propTypes = {
        description: PropTypes.string.isRequired,
        values: PropTypes.arrayOf(PropTypes.any).isRequired
    };

    render() {
        return (
            <tr className="hover:bg-primary-50">
                <td className="py-4 px-6 border-b border-primary-200">
                    <HtmlMessage id={this.props.description}/>
                </td>
                <td className="py-4 px-6 border-b border-primary-200 text-center">
                    {this.props.values[0]}
                </td>
                <td className="py-4 px-6 border-b border-primary-200 text-center">
                    {this.props.values[1]}
                </td>
                <td className="py-4 px-6 border-b border-primary-200 text-center">
                    {this.props.values[2]}
                </td>
            </tr>
        )
    }
}
