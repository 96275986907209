module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-prismjs","options":{"showLineNumbers":false}},{"resolve":"gatsby-remark-autolink-headers","options":{"offsetY":100,"isIconAfterHeader":true}},{"resolve":"gatsby-remark-copy-linked-files","options":{"ignoreFileExtensions":[]}},"gatsby-remark-rewrite-relative-links",{"resolve":"gatsby-remark-check-links"},{"resolve":"gatsby-remark-images","options":{"maxWidth":1200,"linkImagesToOriginal":false,"sizeByPixelDensity":true,"backgroundColor":"#ffffff","quality":70,"withWebp":{"quality":80}}},"gatsby-remark-smartypants"],"remarkPlugins":[[null,{"wrapperComponent":"MultiCodeBlock"}]]},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.marvelmetrix.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"MarvelMetrix","short_name":"MarvelMetrix","start_url":"/","background_color":"#3f51b5","theme_color":"#3f51b5","description":"Web tracking and customer analytics. Made in Germany - for Europeans only","display":"standalone","icon":"src/img/logo.png","crossOrigin":"use-credentials","cache_busting_mode":"name","localize":[{"start_url":"/de/","lang":"de","name":"MarvelMetrix","short_name":"MarvelMetrix","description":"Web-Tracking und Kundenanalyse. Made in Germany - Exklusiv für Europäer"}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
