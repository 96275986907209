import React from "react";
import PropTypes from "prop-types";

export default class NavbarToggle extends React.Component {

    static propTypes = {
        onToggleMenu: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        this.props.onToggleMenu();
    }

    render() {
        return (
            <button
                onClick={this.toggle}
                className="block text-gray-400 hover:text-gray-200 sm:hidden"
            >
                <svg className="h-6 w-6" viewBox="0 0 24 24" fill="currentColor">
                    <path
                        d="M3 6a1 1 0 011-1h16a1 1 0 110 2H4a1 1 0 01-1-1zM3 12a1 1 0 011-1h16a1 1 0 110 2H4a1 1 0 01-1-1zM4 17a1 1 0 100 2h7a1 1 0 100-2H4z"
                    />
                </svg>
            </button>
        )
    }
}

