import React from "react";
import {Link} from "gatsby";
import classNames from "classnames";


export default class NavbarItem extends React.Component {

    static getClassNames({isPartiallyCurrent, isCurrent, href}) {
        const isRootPath = href.endsWith("/") || href.endsWith("/de");
        return {
            className: classNames(
                'block py-4 w-full leading-none font-medium ',
                'md:ml-4 sm:w-auto md:px-4',
                'sm:ml-2 sm:px-2 sm:w-auto sm:pb-3 sm:pt-4 sm:border-b-4 text-primary-600',
                'hover:text-primary-500 hover:border-primary-500 hover:bg-primary-100',
                (isCurrent || (isPartiallyCurrent && !isRootPath)) ? "border-primary-500 text-primary-900" : "sm:border-transparent text-gray-700"
            )
        };
    };

    render() {
        return (
            <Link to={this.props.link} getProps={NavbarItem.getClassNames}>
                {this.props.children}
            </Link>
        )
    }
}

