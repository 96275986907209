import React from "react";
import {graphql, StaticQuery} from "gatsby";
import HtmlMessage from "../../components/html-message";
import { ReactComponent as CircleIcon } from "../../svg/font-awesome/light/check-circle.svg";
import {ComingSoon, BilledSeparately} from "../../components/caw-core/style";


class Title extends React.Component {
    render() {
        return (
            <tr className={'bg-primary-100'}>
                <th className="py-4 px-6 font-bold uppercase text-sm text-primary-800 border-b border-primary-100"
                ><HtmlMessage id={this.props.description}/></th>
                <th className="py-4 px-6 font-bold text-center uppercase text-sm text-primary-800 border-b border-primary-100">Starter</th>
                <th className="py-4 px-6 font-bold text-center uppercase text-sm text-primary-800 border-b border-primary-100">Growth</th>
                <th className="py-4 px-6 font-bold text-center uppercase text-sm text-primary-800 border-b border-primary-100">Pro</th>
            </tr>
        )
    }
}

class ItemAvailable extends React.Component {
    render = () => {
        switch (this.props.status) {
            case 'soon':
                return <td className="py-4 px-6 border-b border-primary-100 text-center"><ComingSoon/></td>;
            case 'separate':
                return <td className="py-4 px-6 border-b border-primary-100 text-center"><BilledSeparately/></td>;
            default:
                return <td className="py-4 px-6 border-b border-primary-100 text-center"><CircleIcon
                    className={'text-primary-800 svg-inline--fa'}/></td>;
        }
    }
}

class ItemNotAvailable extends React.Component {
    render = () =>
        <td className="py-4 px-6 border-b border-primary-100 text-center text-primary-200">&mdash;</td>
}

class FeatureLine extends React.Component {
    render()
    {
        const status = this.props.feature.status;
        const plan = this.props.feature.plan || 'starter';

        const starter = plan==='starter';
        const growth = plan==='starter' || plan==='growth';

        return (
            <tr className="hover:bg-primary-50">
                <td className="py-4 px-6 border-b border-primary-100">
                    <HtmlMessage id={this.props.feature.name}/>
                    {this.props.feature.description && (
                        <p className={'text-gray-500 text-sm'}><HtmlMessage id={this.props.feature.description}/></p>
                    )}
                </td>
                { starter ?  <ItemAvailable status={status}/> : <ItemNotAvailable/>}
                { growth ?  <ItemAvailable status={status}/> : <ItemNotAvailable/>}
                <ItemAvailable status={status}/>
            </tr>
        )
    }
}

class PricingPlanFeatureTable extends React.Component {
    render() {
        return (
            <div className={'mt-8'}>
                <table className="text-left w-full border-collapse bg-white shadow-md rounded">
                    <thead>
                        <Title description={this.props.data.name}/>
                    </thead>
                    <tbody>
                    { this.props.data.features.map((feature) => <FeatureLine key={this.props.data.name+'_'+feature.name} feature={feature}/>) }
                    </tbody>
                </table>
            </div>
        )
    }
}

export default class PricingPlanFeatures extends React.Component {
    render() {
        return (
            <StaticQuery
                query={graphql`
                            query MyQuery {
                              allFeatures {
                                edges {
                                  node {
                                    name
                                    id
                                    features {
                                      name
                                      plan
                                      status
                                      description
                                    }
                                  }
                                }
                              }
                            }
                          `}
                render={
                    (data) => {
                        return data.allFeatures.edges.map((edge) => (
                            <PricingPlanFeatureTable key={edge.node.id} data={edge.node}/>
                        ));
                    }
                }
            />
        )
    };
};

