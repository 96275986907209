import React from "react";
import PropTypes from "prop-types";
import SidebarCategory from "./sidebar-category";

export default class SidebarCategoryList extends React.Component {

    static propTypes = {
        categories: PropTypes.array.isRequired,
        onClick: PropTypes.func
    };

    render() {
        return (
            <div className="md:mt-12">
                {this.props.categories.map((category) => (
                    <SidebarCategory onClick={this.props.onClick} key={category.title} title={category.title} items={category.items}/>
                ))}
            </div>
        )
    };
}

