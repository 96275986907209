import React from "react";
import SidebarNavItem from "./sidebar-navitem";
import PropTypes from "prop-types";

export default class SidebarNavLinks extends React.Component {

    static propTypes = {
        navItems: PropTypes.array.isRequired,
        onClick: PropTypes.func.isRequired
    };


    render() {
        return (
            <div className="sm:hidden">
                {this.props.navItems.map((item) => (
                    <SidebarNavItem key={"side-" + item.link}
                                    link={item.link}
                                    onClick={this.props.onClick}
                    >{item.title}</SidebarNavItem>
                ))}
            </div>
        )
    };
}

