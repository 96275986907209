import React from "react";
import PropTypes from "prop-types";
import {Link} from "gatsby";
import classNames from "classnames";

export default class SidebarCategoryItem extends React.Component {

    static propTypes = {
        title: PropTypes.string.isRequired,
        link: PropTypes.string.isRequired,
        onClick: PropTypes.func.isRequired
    };

    render() {
        return (
            <Link to={this.props.link}
                  activeClassName="text-primary-500 text-sm bg-primary-100 border-primary-300 "
                  className={classNames(
                      'mx-4 block py-1 md:py-2 px-4 my-1 border-l-4 border-primary-100',
                      'text-sm font-medium',
                      'text-gray-700 ',
                      'hover:bg-primary-200 hover:border-primary-400 hover:text-primary-800'
                  )}
                  onClick={this.props.onClick}
            >
                {this.props.title}
            </Link>
        )
    };
}
