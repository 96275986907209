import React from 'react'
import Link from 'gatsby-link'
import footerNavLinks from "../../config/footer-nav";
import PropTypes from "prop-types";


class Footer extends React.Component {

    static propTypes = {
        lang: PropTypes.string.isRequired
    };

    render() {
        const linkStyle = "text-primary-700 visited:text-primary-800 ml-4 first:ml-0";
        const lang = this.props.lang;

        return <footer className="py-4 border-t border-primary-800">
            <div className="container mx-auto px-8 sm:flex justify-between text-primary-700">
                <div>
                    MarvelMetrix &copy; {(new Date().getFullYear())} by CodeAndWeb GmbH
                </div>
                <div>
                    {footerNavLinks[lang].map(({link, title}) => (
                        <Link to={link}
                              key={'footer' + link}
                              className={linkStyle}
                        >{title}</Link>
                    ))}
                </div>
            </div>
        </footer>
    }
}

export default Footer;
