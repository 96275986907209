import React from "react";
import HeroBackground from "../../components/hero-background";
import {graphql, StaticQuery} from 'gatsby'
import HtmlMessage from "../../components/html-message";

export default class IndexHero extends React.Component {
    render() {
        return (
            <StaticQuery
                query={graphql`
                          query IndexHeroQuery {
                                heroImage: file(relativePath: { eq: "parallax-2.jpg" }) {
                                  ...fluidImage
                                },
                          }
                        `}
                render={data => (
                    <HeroBackground img={data.heroImage.childImageSharp.fluid}>
                        <div className="container mx-auto text-center px-8">
                            <h1 className="text-3xl md:text-5xl text-primary-800 font-medium mb-3 leading-tight">
                                <HtmlMessage id={'index.hero.title'}/>
                            </h1>
                            <p className="text-xl md:text-3xl text-primary-700">
                                <HtmlMessage id={'index.hero.text'}/>
                            </p>
                        </div>
                    </HeroBackground>
                )}
            />
        )
    }
}
