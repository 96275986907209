import React from "react";
import PropTypes from "prop-types";
import SidebarCategoryItem from "./sidebar-category-item";

export default class SidebarCategory extends React.Component {

    static propTypes = {
        title: PropTypes.string.isRequired,
        items: PropTypes.array.isRequired,
        onClick: PropTypes.func.isRequired
    };

    render() {
        return (
            <div className={'ml-2 sm:ml-0 mt-4 md:mt-8'}>
                <h4 className={'font-semibold text-sm text-primary-800 uppercase px-4 py-2'}>{this.props.title}</h4>
                <ul>
                    {this.props.items.map((item) => (
                        <li key={'sidebar-'+item.title+item.link}>
                            <SidebarCategoryItem onClick={this.props.onClick} title={item.title} link={item.link}/>
                        </li>
                    ))}
                </ul>
            </div>
        )
    };
}
