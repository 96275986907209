/**
 * Implement Gatsby's SSR (Server Side Rendering) APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/ssr-apis/
 */

import PageLayout from './src/components/caw-core/page-layout';
import React from 'react';

export const wrapPageElement = (
    {element, props}, // eslint-disable-line react/prop-types
    pluginOptions
) => (
    <PageLayout {...props} pluginOptions={pluginOptions}>
        {element}
    </PageLayout>
);
