import React from "react";
import Img from "gatsby-image";

export default class HeroBackground extends React.Component {

    render()
    {
        return (
            <div className={'relative flex content-center justify-center'}>
                <Img
                    css={{top: 0, left: 0, right: 0, bottom: 0}}
                    style={{position: `absolute`, zIndex: -1}}
                    fluid={this.props.img}
                />
                <div className={'flex content-center items-center'}>
                    <div style={{backgroundColor: 'rgba(255, 255, 255, 0.8)'}}
                         className={'p-8 m-10 md:m-32 xl:m-48'}
                    >
                        {this.props.children}
                    </div>
                </div>
            </div>
        );
    }
}

