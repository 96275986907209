
const sideNav = {
    none: null,
    'docs-en': [
        {
            title: "Getting started",
            items: [
                {
                    link: "/documentation/basics",
                    title: "Basic concepts"
                },
            ]
        },
    ],
    'docs-de': [
        {
            title: "Erste Schritte",
            items: [
                {
                    link: "/de/dokumentation/grundlagen",
                    title: "Grundlagen"
                },
            ]
        },
    ]
};

if(process.env.NODE_ENV === 'development')
{
    sideNav['docs-en'] = [
        ...sideNav['docs-en'],
        {
            title: "Markdown",
            items: [
                {
                    link: "/internal/markdown",
                    title: "Markdown"
                },
                {
                    link: "/internal/alerts",
                    title: "Alerts"
                },
                {
                    link: "/internal/typography",
                    title: "Typography"
                },
            ]
        },
    ];
}

export default sideNav;