import React from 'react';
import Helmet from 'react-helmet';
import {graphql, StaticQuery} from 'gatsby';
import PropTypes from 'prop-types';

const SEO = ({frontmatter = {}, lang, postImage}) => (
    <StaticQuery
        query={graphql`
      {
        site {
          siteMetadata {
            siteUrl
            image
            author {
              name
            }
            organization {
              name
              url
              logo
            }
            social {
              twitter
            }
          }
        }
      }
    `}
        render={({site: {siteMetadata: seo}}) => {

            const image = postImage ? `${seo.siteUrl}${postImage}` : seo.image;
            const url = frontmatter.path
                ? `${seo.siteUrl}${frontmatter.path}`
                : seo.siteUrl;
            const title = frontmatter.title;
            const description = frontmatter.description;

            return (
                <>
                    <Helmet>
                        {/* General tags */}
                        <title>{title}</title>
                        <html lang={lang}/>
                        <meta name="description" content={description}/>
                        <meta name="image" content={image}/>

                        {/* OpenGraph tags */}
                        <meta property="og:url" content={url}/>
                        {/*
                        {isBlogPost ? <meta property="og:type" content="article"/> : null}
                        */}
                        <meta property="og:title" content={title}/>
                        <meta property="og:description" content={description}/>
                        <meta property="og:image" content={image}/>

                        {/* Twitter Card tags */}
                        <meta name="twitter:card" content="summary_large_image"/>
                        <meta name="twitter:creator" content={seo.social.twitter}/>
                        <meta name="twitter:title" content={title}/>
                        <meta name="twitter:description" content={description}/>
                        <meta name="twitter:image" content={image}/>
                    </Helmet>
                </>
            );
        }}
    />
);

SEO.propTypes = {
    isBlogPost: PropTypes.bool,
    frontmatter: PropTypes.any.isRequired,
    postImage: PropTypes.string,
    lang: PropTypes.string.isRequired
};

SEO.defaultProps = {
    frontmatter: {},
    postImage: null,
};

export default SEO;
